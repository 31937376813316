<script>
  import {Route} from 'svelte-router-spa';

  // components for this layout
  import AuthNavbar from 'components/Navbars/AuthNavbar.svelte';
  import FooterSmall from 'components/Footers/FooterSmall.svelte';

  const registerBg2 = '../assets/img/pattern_svelte.png';

  export let currentRoute;
  const params = {};
</script>

<div>
  <AuthNavbar />
  <main>
    <section class="relative w-full h-full py-40 min-h-screen">
      <div
        class="absolute top-0 w-full h-full bg-opacity-50 bg-no-repeat bg-full"
        style="background-color: #e8ad90e0;"
      />
      <Route {currentRoute} {params} />
      <FooterSmall absolute="true" />
    </section>
  </main>
</div>
