<script>
  import {fade, scale} from 'svelte/transition';
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {onMount} from 'svelte';
  import {Confirm} from 'svelte-confirm';
  import {showErrorMessage, showSuccessMessage} from '../../utils/toast';
  import CardProductCreate from './CardProductCreate.svelte';
  import CardProductUpdate from './CardProductUpdate.svelte';
  import {dndzone} from 'svelte-dnd-action';
  import {flip} from 'svelte/animate';
  import {overrideItemIdKeyNameBeforeInitialisingDndZones} from 'svelte-dnd-action';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';
  import StoreAddonsGroupList from './StoreAddonGroups/StoreAddonGroupList.svelte';
  import StoreAddonsList from './StoreAddons/StoreAddonsList.svelte';
  import {Tabs, Tab, TabList, TabPanel} from 'svelte-tabs';

  overrideItemIdKeyNameBeforeInitialisingDndZones('product_id');
  const flipDurationMs = 200;

  export let store_id;
  export let brand_id;

  let baseApiURL = morrDashboard.env.API_URL;
  let data;
  let product_id;
  let showCreate = false;
  let showUpdate = false;
  let validationError = null;
  let items = [];
  let showProduct = false;
  let noProduct = 'loading products ...';
  let updatedList = [];
  let recentlyAddedProducts = [];
  let showRecentlyAdded = false;
  let currentUpdateIndex = null;

  function handleSort(e) {
    items = e.detail.items;
  }

  function changeSequence() {
    updatedList = JSON.parse(JSON.stringify(items));
    for (let i = 1; i < updatedList.length; i++) {
      updatedList[i - 1].next_product_id = updatedList[i].product_id;
    }
    updatedList[updatedList.length - 1].next_product_id = null;

    // change category list [cat1_details, cat2_details] to category_ids [1, 2 ...]
    // Put API just expects the IDs only not complete category details
    updatedList.map(function (product) {
      product.category_ids = product.categories.map(function (category) {
        return category.category_id;
      });
      delete product.categories;
      return product;
    });

    axios
      .put(`${baseApiURL}/admin/store/${store_id}/product`, updatedList, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        let successMessage = 'Changes Saved';
        showSuccessMessage(successMessage);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function getProductListApi() {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${baseApiURL}/admin/store/${store_id}/product`, {
        headers: headers
      })
      .then(res => {
        items = res.data.data;
        showProduct = true;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
        showProduct = false;
        noProduct = 'Products not found';
      });
  }
  onMount(() => {
    getProductListApi();
  });

  function deleteProductAPI(productIndex, productID) {
    let headers = {
      'X-Auth-Token': $authToken
    };

    axios
      .delete(`${baseApiURL}/admin/store/${store_id}/product/${productID}`, {
        headers: headers
      })
      .then(res => {
        items.splice(productIndex, 1);
        showProduct = true;
        showSuccessMessage('Succesfully deleted the product');
        getProductListApi();
      })
      .catch(err => {
        console.log('PRODUCT_DELETE_ERR', err);
        parseAndShowErrorMessage(err);
      });
  }

  function addRecentProducts(e) {
    showRecentlyAdded = true;
    recentlyAddedProducts = [...recentlyAddedProducts, e.detail];
  }

  function postToCreateAPI() {
    axios
      .post(
        `${baseApiURL}/admin/store/${store_id}/product`,
        recentlyAddedProducts,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(response => {
        showSuccessMessage('Successfully Added Products');
        showRecentlyAdded = !showRecentlyAdded;
        recentlyAddedProducts = [];
        getProductListApi();
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function deleteProduct(productIndex, productID) {
    deleteProductAPI(productIndex, productID);
  }

  function deleteRecentlyProduct(productIndex) {
    recentlyAddedProducts.splice(productIndex, 1);
    recentlyAddedProducts = [...recentlyAddedProducts];
  }

  function visitUpdate(id, product, items, index) {
    product_id = id;
    data = product;
    showUpdate = !showUpdate;
    currentUpdateIndex = index;
  }

  $: if (data) {
    // After Updating a Product, update local copy as well without api call
    if (currentUpdateIndex != null && data != items[currentUpdateIndex]) {
      items[currentUpdateIndex] = data;
    }
  }
</script>

{#if showCreate}
  <div
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-full my-6 mx-auto max-w-6xl">
      <div class="relative p-6 flex-auto">
        <CardProductCreate
          on:data={addRecentProducts}
          {store_id}
          {brand_id}
          bind:showCreate
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

{#if showUpdate}
  <div
    class="overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-full my-6 mx-auto max-w-6xl">
      <div class="relative p-6 flex-auto">
        <CardProductUpdate
          {store_id}
          {product_id}
          {brand_id}
          bind:data
          bind:showUpdate
        />
      </div>
    </div>
  </div>
  <div class="" />
{/if}
<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-white border-0"
>
  <Tabs>
    <TabList>
      <Tab>Products</Tab>
      <Tab>Addons</Tab>
      <Tab>Addon Groups</Tab>
    </TabList>
    <TabPanel>
      <div>
        <div class="rounded-t bg-white mb-0 px-6 py-6 border-b border-gray-100">
          <div class="header flex">
            <h6 class="text-gray-800 text-xl font-bold">Products</h6>
            <div class="flex justify-end w-6/12">
              <button
                class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                on:click={() => changeSequence()}
              >
                Save changes
              </button>
              <button
                class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                on:click={() => {
                  showCreate = !showCreate;
                }}
              >
                Add Product
              </button>
            </div>
          </div>
        </div>

        {#if showRecentlyAdded}
          <div class="recentProducts">
            <div class="header p-2">
              <h6 class="text-gray-800 text-xl font-bold">
                Recently Added products
              </h6>
              <div>
                <button
                  class="z-10 bg-blue-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  on:click={() => postToCreateAPI()}
                >
                  Save Products
                </button>
                <button
                  class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  on:click={() => {
                    showRecentlyAdded = !showRecentlyAdded;
                  }}
                >
                  Close
                </button>
              </div>
            </div>
            <div class="products">
              {#each recentlyAddedProducts as product, index}
                <div in:scale out:fade>
                  <div class="product p-4">
                    <div class="relative pb-48 overflow-hidden">
                      <img
                        class="absolute inset-0 h-full w-full object-cover"
                        src={product.image_url}
                        alt=""
                      />
                    </div>
                    <div class="p-4">
                      <h2 class="mt-2 mb-2  font-bold">
                        {product.product_name}
                      </h2>
                      <p class="text-sm">
                        {product.description}
                      </p>
                    </div>
                    <div
                      class="p-4  status flex border-t border-b text-xs text-gray-700 justify-evenly"
                    >
                      <div class="flex flex-row font-bold items-center m-2">
                        <p>Available?</p>
                        {#if product.is_available}
                          <i
                            class="fa fa-check-circle text-green-500 text-lg ml-2"
                          />
                        {:else}
                          <i
                            class="fas fa-times-circle text-red-500 text-lg ml-2"
                          />
                        {/if}
                      </div>
                      <div class="flex flex-row font-bold items-center m-2">
                        <p>Deliverable?</p>
                        {#if product.is_deliverable}
                          <i
                            class="fa fa-check-circle text-green-500 text-lg ml-2"
                          />
                        {:else}
                          <i
                            class="fas fa-times-circle text-red-500 text-lg ml-2"
                          />
                        {/if}
                      </div>
                      <div class="flex flex-row font-bold items-center m-2">
                        <p>Pickable?</p>
                        {#if product.is_pickable}
                          <i
                            class="fa fa-check-circle text-green-500 text-lg ml-2"
                          />
                        {:else}
                          <i
                            class="fas fa-times-circle text-red-500 text-lg ml-2"
                          />
                        {/if}
                      </div>
                    </div>
                    <div
                      class="p-4 flex items-center justify-evenly text-sm text-gray-600"
                    >
                      <Confirm
                        themeColor="410"
                        confirmTitle="Delete"
                        cancelTitle="Cancel"
                        let:confirm={confirmThis}
                      >
                        <button
                          on:click={() =>
                            confirmThis(deleteRecentlyProduct, index)}
                        >
                          <i class="fas fa-trash text-red-500" />
                        </button>

                        <span slot="title">
                          Are you sure, you want to delete <span
                            class="font-semibold">{product.product_name}</span
                          >?
                        </span>
                        <span slot="description" class="text-sm">
                          You won't be able to revert this.
                        </span>
                      </Confirm>
                    </div>
                  </div>
                </div>
              {/each}
            </div>
          </div>
        {/if}

        <!-- Grid -->
        <div
          use:dndzone={{items}}
          on:consider={handleSort}
          on:finalize={handleSort}
          class="products"
        >
          {#each items as product, index (product.product_id)}
            <div animate:flip={{duration: flipDurationMs}}>
              <div class="product p-4">
                <div class="relative pb-48 overflow-hidden">
                  <img
                    class="absolute inset-0 h-full w-full object-cover"
                    src={product.image_url}
                    alt=""
                  />
                </div>
                <div class="p-4">
                  <h2 class="mt-2 mb-2  font-bold">
                    {product.product_name}
                  </h2>
                  <p class="text-sm">
                    {product.description}
                  </p>
                  <div class="flex flex-wrap justify-around m-2">
                    {#each product.variations as variation}
                    <div class="variation p-[5px] rounded ml-[3px] text-center">{variation.title}</div>
                    {/each}
                  </div>
                </div>
                <div
                  class="flex flex-wrap border-t border-b text-xs text-gray-700 justify-evenly"
                >
                  <div class="flex flex-row font-bold items-center m-2">
                    <p>Available?</p>
                    {#if product.is_available}
                      <i
                        class="fa fa-check-circle text-green-500 text-lg ml-2"
                      />
                    {:else}
                      <i
                        class="fas fa-times-circle text-red-500 text-lg ml-2"
                      />
                    {/if}
                  </div>
                  <div class="flex flex-row font-bold items-center m-2">
                    <p>Deliverable?</p>
                    {#if product.is_deliverable}
                      <i
                        class="fa fa-check-circle text-green-500 text-lg ml-2"
                      />
                    {:else}
                      <i
                        class="fas fa-times-circle text-red-500 text-lg ml-2"
                      />
                    {/if}
                  </div>
                  <div class="flex flex-row font-bold items-center m-2">
                    <p>Pickable?</p>
                    {#if product.is_pickable}
                      <i
                        class="fa fa-check-circle text-green-500 text-lg ml-2"
                      />
                    {:else}
                      <i
                        class="fas fa-times-circle text-red-500 text-lg ml-2"
                      />
                    {/if}
                  </div>
                </div>
                <div
                  class="p-4 flex items-center justify-evenly text-sm text-gray-600"
                >
                  <span class="ml-2">
                    <button
                      class="active:border-0"
                      on:click={() => {
                        visitUpdate(product.product_id, product, items, index);
                      }}>Update</button
                    >
                  </span>
                  <Confirm
                    themeColor="410"
                    confirmTitle="Delete"
                    cancelTitle="Cancel"
                    let:confirm={confirmThis}
                  >
                    <button
                      on:click={() =>
                        confirmThis(deleteProduct, index, product.product_id)}
                    >
                      <i class="fas fa-trash text-red-500" />
                    </button>

                    <span slot="title">
                      Are you sure, you want to delete <span
                        class="font-semibold">{product.product_name}</span
                      >?
                    </span>
                    <span slot="description" class="text-sm">
                      You won't be able to revert this.
                    </span>
                  </Confirm>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </TabPanel>
    <TabPanel>
      <StoreAddonsList bind:store_id />
    </TabPanel>
    <TabPanel>
      <StoreAddonsGroupList bind:store_id />
    </TabPanel>
  </Tabs>
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  .products {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    align-items: center;
    justify-content: center;
  }

  .product {
    background-color: rgb(231, 221, 207);
    border-radius: 20px;
    margin: 20px;
  }

  .recentProducts {
    box-shadow: 1px 1px 1px 1px gray;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .variation{
    border:1px solid rgb(207, 199, 188);
  }

  @media (max-width: 540px) {
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    button {
      margin: 5px;
    }
  }

  @media (max-width: 1000px) {
    .products {
      display: grid;
      grid-template-columns: 93%;
    }
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    .products {
      display: grid;
      grid-template-columns: 47% 47%;
    }
  }
</style>
