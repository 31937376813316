<script>
  import axios from 'axios';

  import FilePond, {registerPlugin} from 'svelte-filepond';
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import {authToken} from '../../../store/auth';
  import {showErrorMessage, showSuccessMessage} from '../../../utils/toast';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );

  let filepond_input_name = 'filepond';
  let banners_pond;
  let cover_pond;
  let profile_pond;
  let portrait_pond;
  let landscape_pond;

  export let exported_merchant_id;
  export let showComponent;
  export let showPrevComponent;

  let merchantFormFields = {
    images: {
      profile: [],
      cover: [],
      banners: [],
      portrait: [],
      landscape: []
    }
  };

  function getFilePondServerConfig(image_for, image_type) {
    return {
      url: `${morrDashboard.env.API_URL}/admin/images`,
      process: {
        url: '/upload',
        method: 'POST',
        withCredentials: false,
        headers: {
          'X-Auth-Token': $authToken
        },
        timeout: 7000,
        ondata: formdata => {
          formdata.append('image_for', image_for);
          formdata.append('image_type', image_type);
          console.log(banners_pond.getFiles());
          return formdata;
        },
        onload: response => {
          console.log(typeof response);
          response = JSON.parse(response);
          let image_url = response.data.file_url;
          let image_id = response.data.image_id;
          merchantFormFields.images[image_type].push(image_url);
          return image_id;
        },
        onerror: response => {
          let err = JSON.parse(response);
          validationError = (err.hasOwnProperty('detail') && err.detail) || err;
          showErrorMessage();
          return err.detail;
        }
      },
      revert: (uniqueFileId, load, error) => {
        axios
          .delete(`${morrDashboard.env.API_URL}/admin/images/${uniqueFileId}`, {
            headers: {
              'X-Auth-Token': $authToken
            }
          })
          .then(res => {
            console.log('Images before', merchantFormFields.images[image_type]);
            let elementIndex =
              merchantFormFields.images[image_type].indexOf(uniqueFileId);
            merchantFormFields.images[image_type].splice(elementIndex, 1);
            console.log('Images after', merchantFormFields.images[image_type]);
            load();
          })
          .catch(err => {
            parseAndShowErrorMessage(err);
          });
      }
    };
  }

  function performAddImage() {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'MerchantImage'
        })
        .then(function (token) {
          if (token) {
            postToCreateImageApi(token);
          }
        });
    });
  }

  function postToCreateImageApi(token) {
    let payload = {
      images: merchantFormFields.images
    };
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/merchants/onboarding/${exported_merchant_id}/images`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken,
            'x-google-token': token
          }
        }
      )
      .then(response => {
        showSuccessMessage('Images Added.');
        showComponent = 'Final';
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function handleSave() {
    performAddImage();
  }

  function handlePrev() {
    showPrevComponent = 'Discount';
  }
</script>

<div class="main">
  <h6 class="title text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
    Upload Images
  </h6>

  <div class="w-full image_full">
    <small class="font-bold">Upload Cover Photo</small>
    <FilePond
      required={true}
      bind:this={cover_pond}
      {filepond_input_name}
      server={getFilePondServerConfig('merchants', 'cover')}
      allowMultiple={true}
    />
  </div>
  <div class="w-full image_full">
    <small class="font-bold">Upload Profile Photo</small>
    <FilePond
      required={true}
      bind:this={profile_pond}
      {filepond_input_name}
      server={getFilePondServerConfig('merchants', 'profile')}
      allowMultiple={true}
    />
  </div>

  <div class="w-full image_multi">
    <small class="font-bold">Upload Carousel Image</small>
    <FilePond
      required={true}
      bind:this={banners_pond}
      {filepond_input_name}
      server={getFilePondServerConfig('merchants', 'cover')}
      allowMultiple={true}
    />
  </div>

  <div class="w-full image_multi">
    <small class="font-bold">Upload Recent Photos</small>
    <FilePond
      required={true}
      bind:this={portrait_pond}
      {filepond_input_name}
      server={getFilePondServerConfig('merchants', 'portrait')}
      allowMultiple={true}
    />
  </div>
</div>

<div class="next-btn">
  <button
    class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
    type="button"
    on:click={handlePrev}
  >
    Prev
  </button>
  <button
    class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
    type="button"
    on:click={handleSave}
  >
    Save
  </button>
</div>

<style>
  .title {
    justify-content: center;
    text-align: center;
    font-size: larger;
  }

  .next-btn {
    justify-content: center;
    text-align: center;
    margin: 20px auto;
  }
</style>
