<script>
  import {Navigate, routeIsActive} from 'svelte-router-spa';
  import UserDropdown from 'components/Dropdowns/UserDropdown.svelte';
  import {isSuperAdmin} from '../../store/admin';

  let collapseShow = 'hidden';

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6 text-white"
  style="background-color:#e8ad91; color: #fff;"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('morr-background-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars" />
    </button>
    <!-- Brand -->
    <a
      class=" md:block md:pb-2 text-gray-100 mr-0 inline-block justify-center text-center whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
      href="/"
    >
      <img
        class="w-24 my-6 container mx-auto"
        src="https://morrhq.s3.ap-south-1.amazonaws.com/morr-white-transparent.png"
        alt="Welcome to Morr"
        title="Welcome to Morr"
      />
    </a>
    <!-- User -->
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <UserDropdown />
      </li>
    </ul>
    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              class="md:block text-left md:pb-2 text-gray-100 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
              href="/"
            >
              <img
                class="w-16 my-6"
                src="https://morrhq.s3.ap-south-1.amazonaws.com/morr-320x.png"
                alt="Welcome to Morr"
                title="Welcome to Morr"
              />
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow('hidden')}
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </div>
      </div>

      <!-- Form -->
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="px-3 py-2 h-12 border border-solid border-gray-600 placeholder-gray-400 text-gray-100 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Dashboard
      </h6>
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <Navigate
            to="/merchants/dashboard"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-home mr-2 text-sm "
              class:active={routeIsActive('/merchants/dashboard')}
            />
            Home
          </Navigate>
        </li>
      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Merchants
      </h6>
      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <Navigate
            to="/merchants/list"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-list mr-2 text-sm"
              class:active={routeIsActive('/merchants/list')}
            />
            List Merchants
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate
            to="/merchants/create"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-store-alt mr-2 text-sm"
              class:active={routeIsActive('/merchants/create')}
            />
            Add Merchant
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate
            to="/merchants/managers/add"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-user-shield mr-2 text-sm"
              class:active={routeIsActive('/merchants/managers/add')}
            />
            Add Manager
          </Navigate>
        </li>
      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Transactions
      </h6>
      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <Navigate
            to="/payments/cash-pay-direct"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-money-bill-alt mr-2 text-sm"
              class:active={routeIsActive('/payments/cash-pay-direct')}
            />
            Approve Cash
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate
            to="/payments/transactions/history"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-receipt mr-2 text-sm"
              class:active={routeIsActive('/payments/transactions/history')}
            />
            History
          </Navigate>
        </li>
      </ul>
      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline"
      >
        BRANDS
      </h6>
      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <Navigate
            to="/merchants/brands/list"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-list mr-2 text-sm"
              class:active={routeIsActive('/merchants/brands')}
            />
            List Brands
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate
            to="/merchants/brands/create"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-store-alt mr-2 text-sm"
              class:active={routeIsActive('/merchants/create/brand')}
            />
            Add Brand
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate
            to="/merchants/access/merchant"
            styles="text-sm capitalize py-3 font-bold block"
          >
            <i
              class="fas fa-user-shield mr-2 text-sm"
              class:active={routeIsActive('/merchants/access/merchant')}
            />
            Merchant Access
          </Navigate>
        </li>
      </ul>
      {#if $isSuperAdmin}
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline"
        >
          EMAIL TEMPLATES
        </h6>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <Navigate
              to="/admin/email-template/list"
              styles="text-sm capitalize py-3 font-bold block"
            >
              <i
                class="fas fa-list mr-2 text-sm"
                class:active={routeIsActive('/admin/email-template/list')}
              />
              List
            </Navigate>
          </li>
          <li class="items-center">
            <Navigate
              to="/admin/email-template/create"
              styles="text-sm capitalize py-3 font-bold block"
            >
              <i
                class="fas fa-envelope-open-text mr-2 text-sm"
                class:active={routeIsActive('/admin/email-template/create')}
              />
              Add New
            </Navigate>
          </li>
        </ul>
      {/if}
      {#if $isSuperAdmin}
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline"
        >
          App Home Page
        </h6>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <Navigate
              to="/admin/homepage/rows"
              styles="text-sm capitalize py-3 font-bold block"
            >
              <i
                class="fas fa-list mr-2 text-sm"
                class:active={routeIsActive('/admin/homepage/rows')}
              />
              Rows
            </Navigate>
            <Navigate
              to="/admin/homepage/promobanners"
              styles="text-sm capitalize py-3 font-bold block"
            >
              <i
                class="fas fa-list mr-2 text-sm"
                class:active={routeIsActive('/admin/homepage/promobanners')}
              />
              Promo Banners
            </Navigate>
            <Navigate
                to="/admin/homepage/quicklinks"
                styles="text-sm capitalize py-3 font-bold block"
            >
              <i
                class="fas fa-list mr-2 text-sm"
                class:active={routeIsActive('/admin/homepage/quicklinks')}
              />
              Quick Links
            </Navigate>
          </li>
        </ul>
      {/if}
    </div>
  </div>
</nav>

<style>
  @import 'https://unpkg.com/@responsive-ui/accordion@latest/lib/index.css';
</style>
