<script>
  import {form, bindClass} from 'svelte-forms';
  import axios from 'axios';
  import {onMount} from 'svelte';
  import FilePond, {registerPlugin} from 'svelte-filepond';
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import {authToken} from '../../store/auth';
  import Select from 'svelte-select';
  import {showErrorMessage, showSuccessMessage} from '../../utils/toast';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';
  import {createEventDispatcher} from 'svelte';
  import ProductVariationMultiple from './ProductVariations/ProductVariationMultiple.svelte';

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );

  export let store_id;
  export let brand_id;
  export let showCreate;
  export let variationInfoList = [];
  export let deletedVariationIds = [];

  let baseApiURl = morrDashboard.env.API_URL;
  let baseApiURL_V2 = morrDashboard.env.API_V2_URL;
  let dispatch = createEventDispatcher();
  let categories;
  let categories_list = [];
  let selectedProduct = [];
  let templateItems = [];
  let templateData;
  let template_id;
  let selectedGroupDetail = [];
  let groupItems = [];
  let groupList = [];

  let product_pond;
  let filepond_input_name = 'filepond';
  let validationError;
  let productFormFields = [
    {
      product_name: '',
      price: 0.0,
      is_deliverable: true,
      is_pickable: true,
      is_available: true,
      is_first_in_list: true,
      image_urls: [],
      description: '',
      next_product_id: '',
      category_ids: [],
      group_ids: []
    }
  ];

  const createProductForm = form(
    () => ({
      product_name: {
        value: productFormFields[0].product_name,
        validators: ['required']
      },
      is_deliverable: {
        value: productFormFields[0].is_deliverable,
        validators: ['required']
      },
      is_pickable: {
        value: productFormFields[0].is_pickable,
        validators: ['required']
      },
      is_available: {
        value: productFormFields[0].is_available,
        validators: ['required']
      },
      is_first_in_list: {
        value: productFormFields[0].is_first_in_list
      },
      image_urls: {
        value: productFormFields[0].image_urls,
        validators: ['required']
      },
      description: {
        value: productFormFields[0].description,
        validators: ['required']
      },
      next_product_id: {
        value: productFormFields[0].next_product_id
      },
      category_ids: {
        value: productFormFields[0].category_ids
      },
      group_ids: {value: productFormFields[0].group_ids}
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function getGroups() {
    axios
      .get(`${baseApiURL_V2}/admin/store/${store_id}/addons/groups/`, {
        headers: {
          'X-auth-token': $authToken
        }
      })
      .then(response => {
        groupItems = response.data.data;
        groupItems.forEach(group => {
          if (group.store_id === store_id) {
            groupList.push({label: group.name, value: group.group_id});
          }
        });
        groupList = groupList; //Due to svelte render
      })
      .catch(err => {
        // parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    if (brand_id) {
      showProductTemplateListApi();
    }
    listAllCategories();
    getGroups();
  });

  function getProduct() {
    return {
      product_name: productFormFields[0].product_name,
      price: productFormFields[0].price,
      is_deliverable: productFormFields[0].is_deliverable,
      is_pickable: productFormFields[0].is_pickable,
      is_available: productFormFields[0].is_available,
      image_url: getUploadImageUrls()[0],
      description: productFormFields[0].description,
      category_ids: productFormFields[0].category_ids,
      group_ids: [...new Set(productFormFields[0].group_ids)],
      variations: variationInfoList
    };
  }

  function getUploadImageUrls() {
    return productFormFields[0].image_urls;
  }

  function buildFormPayload() {
    return getProduct();
  }

  function createProduct() {
    validationError = null;

    if (!productFormFields[0].product_name) {
      validationError = 'Name is required';
    } 
     else if (productFormFields[0].image_urls.length < 1) {
      validationError = 'Add image';
    } else if (!productFormFields[0].description) {
      validationError = 'Description is required';
    }

    let payload = buildFormPayload();

    validationError = validateVariations(payload.variations, validationError)

    if (validationError) {
      showErrorMessage(validationError);
      return;
    }
    dispatch('data', payload);
    showCreate = !showCreate;
  }

  function validateVariations(variations, validationError){
    if (variations.length < 1){
      validationError = 'Add atleast one variation';
    }
    else{
      let countDefaultVariation = 0;
      variations.forEach(variation => {
        if (variation.is_default){
          countDefaultVariation ++;
        }
      });
      if (countDefaultVariation > 1){
        validationError = 'Add only one default variation';
      }
      else if (countDefaultVariation < 1){
        validationError = 'Add one default variation';
      }
    }
    return validationError;
  }

  function getFilePondServerConfig(image_for, image_type) {
    return {
      url: `${baseApiURl}/admin/images`,
      load: async (source, load, error, progress, abort, headers) => {
        fetch(source, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            Origin: window.location.origin
          }
        }).then(function (response) {
          response
            .blob()
            .then(function (imageBlob) {
              load(imageBlob);
            })
            .catch(function (_error) {
              error(_error);
            });
        });
      },
      process: {
        url: '/upload',
        method: 'POST',
        withCredentials: false,
        headers: {
          'X-Auth-Token': $authToken
        },
        timeout: 7000,
        ondata: formdata => {
          formdata.append('image_for', image_for);
          formdata.append('image_type', image_type);
          return formdata;
        },
        onload: response => {
          console.log(typeof response);
          response = JSON.parse(response);
          let file_url = response.data.file_url;
          let image_id = response.data.image_id;
          productFormFields[0].image_urls.push(file_url);
          return image_id;
        },
        onerror: response => {
          let err = JSON.parse(response);
          parseAndShowErrorMessage(err);
          return err.detail;
        }
      },
      revert: (uniqueFileId, load, error) => {
        axios
          .delete(`${baseApiURl}/admin/images/${uniqueFileId}`, {
            headers: {
              'X-Auth-Token': $authToken
            }
          })
          .then(res => {
            let elementIndex =
              productFormFields[0].image_urls.indexOf(uniqueFileId);
            productFormFields[0].image_urls.splice(elementIndex, 1);
            load();
          })
          .catch(err => {
            parseAndShowErrorMessage(err);
            console.error('FAILED_TO_REMOVE_IMAGE', err);
            error(err);
          });
      }
    };
  }

  function listAllCategories() {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${baseApiURl}/admin/store/category?store_id=${store_id}`, {
        headers: headers
      })
      .then(res => {
        categories = res.data.data;
        categories.forEach(category => {
          if (category.store_id === store_id) {
            categories_list.push({
              label: category.name,
              value: category.category_id
            });
          }
        });
        categories_list = categories_list;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function showProductTemplateListApi() {
    axios
      .get(
        `${morrDashboard.env.API_URL}/admin/merchants/product_template/list`,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(res => {
        let templateList = res.data.data;
        let productTemplateList = [];
        templateList.forEach(template => {
          if (template.brand_id == brand_id) {
            productTemplateList.push({
              label: template.name,
              value: template.product_template_id
            });
          }
        });
        templateItems = productTemplateList;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function handleSelect(event) {
    selectedProduct = event.detail || [];
    selectedProduct.forEach(selected => {
      productFormFields[0].category_ids.push(selected.value);
    });
  }

  function handleGroups(e) {
    selectedGroupDetail = e.detail || [];
    selectedGroupDetail.forEach(selectedGroup => {
      productFormFields[0].group_ids.push(selectedGroup.value);
    });
  }

  function resetGroups(e) {
    productFormFields[0].group_ids = [];
  }

  function handleTemplateSelect(event) {
    let selectedTemplate = event.detail || [];
    template_id = selectedTemplate.value;
    showProductTemplateDataApi(template_id);
  }

  function showProductTemplateDataApi(template_id) {
    axios
      .get(
        `${morrDashboard.env.API_URL}/admin/brand/${brand_id}/product/template/${template_id}`,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(res => {
        templateData = res.data.data;
        productFormFields[0].product_name = templateData.name;
        productFormFields[0].description = templateData.description;
        productFormFields[0].image_urls[0] = templateData.image_url;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function handleTemplateReset() {
    template_id = null;
    productFormFields[0].product_name = '';
    productFormFields[0].description = '';
    productFormFields[0].price = 0;
    productFormFields[0].image_urls = [];
  }
</script>

<div
  class="relative overflow-y-auto h-fit flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-200 border-0 create-product-modal"
>
  <div class="flex-auto px-4 lg:px-4 py-4 pt-0">
    <form>
      <div class="flex justify-between">
        <h6 class="text-gray-500 text-sm mt-3 mb-3 font-bold uppercase">
          Product Information
        </h6>
      </div>
      <div class="">
        {#if brand_id}
          <Select
            containerStyles="min-height: 3rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1rem; vertical-align:middle; margin:20px"
            inputAttributes={{
              class:
                'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600'
            }}
            inputStyles="font-size: 1.125rem;"
            items={templateItems}
            isMulti={false}
            placeholder="Select a Product Template to autofill"
            noOptionsMessage="No templates found"
            on:select={handleTemplateSelect}
            on:clear={handleTemplateReset}
          />
        {/if}
      </div>
      <div class="flex flex-col md:flex-row justify-between">
        <div class=" w-full md:w-7/12">
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  for="product-name"
                >
                  Product Name
                </label>
                <input
                  id="product-name"
                  type="text"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Please enter your product Name"
                  bind:value={productFormFields[0].product_name}
                  use:bindClass={{
                    form: createProductForm,
                    name: 'product_name'
                  }}
                />
              </div>
            </div>
          <div class="px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="description"
              >
                Description
              </label>
              <textarea
                id="description"
                type="text"
                class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="please enter description"
                bind:value={productFormFields[0].description}
                use:bindClass={{
                  form: createProductForm,
                  name: 'description'
                }}
              />
            </div>
          </div>
          <div class="w-full px-4">
            <div class="relative w-full">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="images"
              >
                Images
              </label>
              {#if template_id}
                <FilePond
                  required={true}
                  bind:this={product_pond}
                  labelIdle={'Template Image can be edited from Product Templates sections only'}
                  {filepond_input_name}
                  server={{
                    load: async (
                      source,
                      load,
                      error,
                      progress,
                      abort,
                      headers
                    ) => {
                      fetch(source, {
                        method: 'GET',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                          Origin: window.location.origin
                        }
                      })
                        .then(function (response) {
                          response.blob().then(function (imageBlob) {
                            load(imageBlob);
                          });
                        })
                        .catch(function (_error) {
                          error(_error);
                        });
                    }
                  }}
                  allowBrowse={false}
                  allowPaste={false}
                  allowDrop={false}
                  allowProcess={false}
                  allowMultiple={false}
                  allowRemove={false}
                  files={productFormFields[0].image_urls[0]}
                />
              {:else}
                <FilePond
                  required={true}
                  bind:this={product_pond}
                  {filepond_input_name}
                  server={getFilePondServerConfig('products', 'product')}
                  allowMultiple={false}
                />
              {/if}
            </div>
          </div>
        </div>
        <div class="w-full md:w-4/12">
          <div class="flex text-center">
            <div class="px-1">
              <div class="relative w-full mb-3">
                <label
                  class="uppercase text-gray-700 text-xs font-bold mb-2"
                  for="store-is-delivering"
                >
                  Available?
                </label>

                <input
                  type="checkbox"
                  id="store-is-delivering"
                  class="form-checkbox text-gray-800 ml-1 w-10 h-10 ease-linear transition-all duration-150"
                  placeholder="Is delivering"
                  bind:checked={productFormFields[0].is_available}
                />
              </div>
            </div>
            <div class="px-1">
              <div class="relative w-full mb-3 text-center">
                <label
                  class="uppercase text-gray-700 text-xs font-bold mb-2"
                  for="store-is-delivering"
                >
                  Deliverable?
                </label>

                <input
                  type="checkbox"
                  id="store-is-delivering"
                  class="form-checkbox text-gray-800 ml-1  w-10 h-10 ease-linear transition-all duration-150"
                  placeholder="Is delivering"
                  bind:checked={productFormFields[0].is_deliverable}
                />
              </div>
            </div>
            <div class="px-1">
              <div class="relative w-full mb-3">
                <label
                  class="uppercase text-gray-700 text-xs font-bold mb-2"
                  for="store-is-delivering"
                >
                  Pickable?
                </label>

                <input
                  type="checkbox"
                  id="store-is-delivering"
                  class="form-checkbox text-gray-800 ml-1 w-10 h-10 ease-linear transition-all duration-150"
                  placeholder="Is delivering"
                  bind:checked={productFormFields[0].is_pickable}
                />
              </div>
            </div>
          </div>
          <div class="px-1">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="categories"
              >
                Categories
              </label>
              <Select
                containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle"
                inputAttributes={{
                  class:
                    'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 outline-none text-lg text-gray-600'
                }}
                inputStyles="font-size: 1.125rem;"
                items={categories_list}
                isMulti={true}
                placeholder="Please select one or more categories"
                noOptionsMessage="No Categories available"
                on:select={handleSelect}
              />
            </div>
          </div>
          <div class="px-1">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="groups"
              >
                Addon Groups
              </label>
              <Select
                containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle"
                inputAttributes={{
                  class:
                    'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 outline-none text-lg text-gray-600'
                }}
                inputStyles="font-size: 1.125rem;"
                items={groupList}
                isMulti={true}
                placeholder="Please select one or more groups"
                noOptionsMessage="No Groups available"
                on:select={handleGroups}
                on:reset={resetGroups}
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Variations -->
      <div class="flex flex-col w-full">
        {#if variationInfoList}
          <ProductVariationMultiple
            bind:variationInfoList
            bind:deletedVariationIds
          />
        {/if}
      </div>
      <div class="px-2">
        <div class="relative flex items-center justify-center w-full m-4">
          <button
            class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            type="submit"
            on:click|preventDefault={() => {
              createProduct();
            }}
          >
            Save
          </button>
          <button
            class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            type="submit"
            on:click|preventDefault={() => {
              showCreate = !showCreate;
            }}
          >
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }

  @media screen and (max-width: 480px) {
    .create-product-modal {
      height: auto;
      min-height: 300px;
      max-height: 400px;
    }
  }
  @media screen and (max-width: 720px) {
    .create-product-modal {
      height: auto;
      min-height: 460px;
      max-height: 600px;
    }
  }
  @media screen and (min-width: 1080px) {
    .create-product-modal {
      height: auto;
      min-height: 560px;
      max-height: 600px;
    }
  }
</style>
