<script>
  import Carousel from '@beyonk/svelte-carousel';
  import {ChevronLeftIcon, ChevronRightIcon} from 'svelte-feather-icons';
  import QrCode from 'svelte-qrcode';

  export let merchant;
  export let transactionStats;

  function generateQRCodeJSON(merchant) {
    let merchantData = {
      merchantUid: merchant.merchant_id,
      merchantPageUri:
        'https://app.morr.my/tabs/payments/pay/' + merchant.merchant_id,
      playStore: 'https://play.google.com/store/apps/details?id=io.morr.deals',
      appStore: 'https://apps.apple.com/my/app/morr/id1544728152'
    };
    return JSON.stringify(merchantData);
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16"
>
  <div class="px-6">
    <div class="flex flex-wrap justify-center">
      <div class="w-full px-4 flex justify-center ring-2 ring-offset-gray-50">
        <div class="relative">
          <img
            alt={merchant.name}
            title={merchant.name}
            src={merchant.images?.profile?.length > 0
              ? merchant.images.profile[0]
              : 'https://app.morr.my/assets/icon/morr-white-transparent.png'}
            class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-21 lg: max-w-150-px"
            style="background-color: #e5a68c;"
          />
        </div>
      </div>

      {#if transactionStats}
        <div class="w-full px-4 text-center mt-20">
          <div class="flex justify-center py-4 lg:pt-4 pt-8">
            <div class="mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-gray-700"
              >
                {transactionStats?.total || 0}
              </span>
              <span class="text-sm text-gray-500"
                >Total Transactions count
              </span>
            </div>
            <div class="mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-gray-700"
              >
                {transactionStats?.success || 0}
              </span>
              <span class="text-sm text-gray-500">Successful Transactions</span>
            </div>
            <div class="lg:mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-gray-700"
              >
                RM {transactionStats?.amount_earned || 0}
              </span>
              <span class="text-sm text-gray-500">Total Earnings</span>
            </div>
          </div>
        </div>
      {/if}
    </div>
    <div class="text-center mt-12">
      <div class="mx-auto justify-center">
        <QrCode value={generateQRCodeJSON(merchant)} style="margin: 0 auto" />
      </div>

      <h3 class="text-xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
        {merchant.name}
      </h3>
      <div
        class="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase"
      >
        <a
          target="_blank"
          href={merchant.address?.google_map_link}
          rel="nourlopener noreferrer"
        >
          <i class="fas fa-map-marker-alt mr-2 text-lg text-gray-500" />
          {merchant.address?.city}, {merchant.address?.country}
        </a>
      </div>
    </div>
    <div class="mt-10 py-10 border-t border-gray-300 text-center">
      <div class="flex flex-wrap justify-center">
        {#if merchant.images?.portrait?.length > 0}
          <div class="w-full">
            <h6
              class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase text-left"
            >
              Portraits (Recent Images)
            </h6>
            <Carousel class="w-11/12" perPage="4">
              <span class="control" slot="left-control">
                <ChevronLeftIcon />
              </span>

              {#each merchant.images.portrait as src, imageIndex (src)}
                <div class="slide-content p-2">
                  <img {src} alt="nature" />
                </div>
              {/each}

              <span class="control" slot="right-control">
                <ChevronRightIcon />
              </span>
            </Carousel>
          </div>
        {/if}

        {#if merchant.images?.banners?.length > 0}
          <div class="w-full">
            <h6
              class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase text-left"
            >
              Banners
            </h6>
            <Carousel class="w-11/12" perPage="2">
              <span class="control" slot="left-control">
                <ChevronLeftIcon />
              </span>

              {#each merchant.images.banners as src, imageIndex (src)}
                <div class="slide-content p-2">
                  <img {src} alt={merchant.name} title={merchant.name} />
                </div>
              {/each}

              <span class="control" slot="right-control">
                <ChevronRightIcon />
              </span>
            </Carousel>
          </div>
        {/if}
      </div>
    </div>

    <div class="mt-10 py-10 border-t border-gray-300 text-left">
      <div class="flex flex-wrap justify-between">
        <div class="w-4/12">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            Contact Numbers
          </h6>

          <div class="w-full">
            {#each merchant.contact_numbers as contact, contactIndex (contact)}
              <div
                class="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase"
              >
                <i class="fas fa-phone mr-2 text-lg text-gray-500" />
                +{contact.country_code} - {contact.phone_number}
                {#if contact.is_whatsapp_contact}
                  <i class="fab fa-whatsapp" />
                {/if}
              </div>
            {/each}
          </div>
        </div>

        <div class="w-4/12">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            Schedule
          </h6>
          <div class="w-full">
            {#each merchant.schedule as scheduler, scheduleIndex (scheduler)}
              <div
                class="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase"
              >
                <i class="fas fa-clock mr-2 text-lg text-gray-500" />
                {scheduler.start} - {scheduler.end}
              </div>
            {/each}
          </div>
        </div>

        <div class="w-4/12">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            Discount Tiers
          </h6>
          <div class="w-full">
            {#each Object.entries(merchant.point_tiers) as [tier_name, tier_info]}
              <div
                class={`text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase ${tier_name}`}
              >
                <i class="fas fa-money-bill-alt mr-2 text-lg text-gray-500" />
                {tier_name} | {tier_info.thresold} | {tier_info.type} | {tier_info.increment_by}
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .bronze {
    color: #cd7f32;
  }
  .silver {
    color: #c0c0c0;
  }
  .gold {
    color: #ffd700;
  }
  :global(.qrcode) {
    margin: 0 auto !important;
  }
</style>
