<script>
  export let productTemplateInfo;
</script>

<div
  class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16"
>
  <div class="px-6">
    <div class="flex flex-wrap justify-center">
      <div class="w-full px-4 flex justify-center ring-2 ring-offset-gray-50">
        <div class="relative">
          <img
            alt={productTemplateInfo.name}
            title="productTitle"
            src="https://app.morr.my/assets/icon/morr-white-transparent.png"
            class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-21 lg: max-w-150-px"
            style="background-color: #e5a68c;"
          />
        </div>
      </div>
    </div>
    <div class="information m-8 p-28 pb-4 items-center text-center shadow-2xl">
      <div class="name-div m-4">
        <h1 class="name font-bold text-3xl uppercase underline">
          {productTemplateInfo.name}
        </h1>
      </div>
      <div class="price-div m-8">
        <h3 class="price font-semibold text-2xl text-red-400">
          RM <span
            style="font-variant-numeric: oldstyle-nums; font-size: 40px; color:gray;"
          >
            {productTemplateInfo.price}</span
          >
        </h3>
        <span class="text-md text-gray-500 underline">Price</span>
      </div>
      <div class="description-div">
        <span class="text-md text-gray-500 underline">About: </span>
        <h2
          class="description font-normal text-lg font-mono text-center m-2 text-center text-black"
        >
          {productTemplateInfo.description}
        </h2>
      </div>
      <div class="images-div m-8 p-4">
        <h3 class="img-heading text-left">Recent Images:</h3>
        <div class="imagediv flex m-4 justify-center align-center">
          <img src={productTemplateInfo.image_url} alt="" />
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  :global(.qrcode) {
    margin: 0 auto !important;
  }
</style>
