<script>
  import {onMount} from 'svelte';
  import {Navigate} from 'svelte-router-spa';
  import {navigateTo} from 'svelte-router-spa';
  import {form, bindClass} from 'svelte-forms';
  import axios from 'axios';
  import {showSuccessMessage, showErrorMessage} from '../../utils/toast';
  import setAuthCreds from '../../utils/auth';

  import initPhoneValidator from '../../utils/phone-number';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';

  let code = null;
  let username = '';
  let password = '';
  let rememberMe = false;
  let isEmail = true;
  let isLoginScreen = true;
  let isOTPScreen = false;
  let otp;
  let otpResendCountDown = 0;
  let isValidMobileNumber = true;

  let emailMobileHandlerElement;
  let emailMobileHandlerInstance;

  $: emailMobileHandlerElement;

  let isMounted = false;

  let showFormValidationError = {};

  $: if (username && isMounted) {
    let selectedCountryCode = code
      ? code
      : emailMobileHandlerInstance.getSelectedCountryData().dialCode;
    emailMobileHandlerInstance.setNumber(`+${selectedCountryCode}${username}`);
  }

  let loginForm = form(
    () => ({
      username: {value: username, validators: ['required']},
      password: {value: password, validators: ['required']}
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  let otpForm = form(
    () => ({
      otp: {value: otp, validators: ['required']}
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function initializeCountDown() {
    otpResendCountDown = 60;
    let countDown = setInterval(() => {
      if (otpResendCountDown > 0) {
        otpResendCountDown--;
      } else {
        clearInterval(countDown);
      }
    }, 1000);
  }

  function requestLoginApi(googleRecaptchaToken) {
    let payload = {
      form_data: {
        code,
        username,

        password,
        is_email: isEmail
      },
      captcha: {
        captcha_token: googleRecaptchaToken
      }
    };

    axios
      .post(`${morrDashboard.env.API_URL}/users/omni-login`, payload)
      .then(res => {
        console.log('LOGGING_IN', res);

        setAuthCreds(
          true,
          res.data.access_token || '',
          res.data.refresh_token || ''
        );

        showSuccessMessage('Successfully logged in');

        navigateTo('/merchants/dashboard');
      })
      .catch(err => {
        console.log('request failed', err.response);
        setAuthCreds(false, '', '');
        parseAndShowErrorMessage(err);
      });
  }

  function requestLoginOTP(googleRecaptchaToken) {
    let payload = {
      code,
      mobile: username
    };

    axios
      .post(`${morrDashboard.env.API_URL}/users/login`, payload, {
        headers: {'X-GOOGLE-TOKEN': googleRecaptchaToken}
      })
      .then(res => {
        console.log('SENDING OTP', res);
        let recipient = isEmail
          ? username
          : emailMobileHandlerInstance.getNumber();
        showSuccessMessage(`Successfully Sent Otp to ${recipient}`);
        isOTPScreen = true;
        isLoginScreen = false;
        initializeCountDown();
      })
      .catch(err => {
        console.log('request failed', err.response);
        setAuthCreds(false, '', '');
        parseAndShowErrorMessage(err);
        isOTPScreen = false;
        isLoginScreen = true;
      });
  }

  function requestLoginWithOTP(googleRecaptchaToken) {
    let payload = {
      code,
      mobile: username,
      otp: otp
    };

    axios
      .post(`${morrDashboard.env.API_URL}/users/verify`, payload, {
        headers: {
          'X-GOOGLE-TOKEN': googleRecaptchaToken,
          'x-platform': 'dashboard'
        }
      })
      .then(res => {
        console.log('LOGGING_IN_WITH_OTP', res);

        setAuthCreds(
          true,
          res.data.access_token || '',
          res.data.refresh_token || ''
        );

        showSuccessMessage('Successfully logged in');

        navigateTo('/merchants/dashboard');
      })
      .catch(err => {
        console.log('request failed', err.response);
        setAuthCreds(false, '', '');
        parseAndShowErrorMessage(err);
      });
  }

  function resetForm() {
    isOTPScreen = false;
    isLoginScreen = true;

    // re-render intl-tel-input plugin because it is not re-rendered when switching between screens
    setTimeout(function () {
      emailMobileHandlerInstance.destroy();
      emailMobileHandlerInstance = null;
      emailMobileHandlerElement.style.paddingLeft = '0px';
      emailMobileHandlerHandler();
    }, 10);
  }

  function performLogin(mode = 'password', action = 'request_otp') {
    loginForm.validate();
    if ($loginForm.fields.username?.errors?.includes('required')) {
      showErrorMessage('Please enter email/ mobile number');
      return;
    } else if (mode === 'password' && !password) {
      showErrorMessage('Please enter password');
      return;
    }

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'Login'
        })
        .then(function (token) {
          if (token) {
            if (mode === 'password') {
              requestLoginApi(token);
            } else if (mode === 'otp' && action === 'verify') {
              requestLoginWithOTP(token);
            } else if (mode === 'otp' && action === 'request_otp') {
              requestLoginOTP(token);
            }
          }
        });
    });
  }

  function enableFormValidationError(fieldName) {
    showFormValidationError[fieldName] = true;
    console.log(showFormValidationError);
  }

  function toggleCheckbox() {
    let temp = !rememberMe;
    rememberMe = temp;
  }

  function checkEmailTypeValidity() {
    isEmail =
      !/^[- +()]*[0-9][- +()0-9]*$/.test(username) ||
      username.indexOf('@') !== -1;
  }

  function emailMobileHandlerHandler() {
    checkEmailTypeValidity();
    if (!isEmail && username) {
      if (!emailMobileHandlerInstance) {
        emailMobileHandlerInstance = initPhoneValidator(
          emailMobileHandlerElement
        );
        code = !code
          ? emailMobileHandlerInstance.getSelectedCountryData().dialCode ||
            '+60'
          : code;
        if (username && code) {
          emailMobileHandlerInstance.setNumber(`+${code}${username}`);
        }
        emailMobileHandlerElement.addEventListener(
          'countrychange',
          function () {
            let selectedCountryCode =
              emailMobileHandlerInstance.getSelectedCountryData().dialCode;
            code = selectedCountryCode;
          }
        );
      } else {
        emailMobileHandlerInstance.setNumber(
          `${emailMobileHandlerInstance.getNumber()}`
        );
      }
      isValidMobileNumber = emailMobileHandlerInstance.isValidNumber();
    } else if (emailMobileHandlerInstance) {
      emailMobileHandlerInstance.destroy();
      emailMobileHandlerInstance = null;
      emailMobileHandlerElement.style.paddingLeft = '0px';
      isValidMobileNumber = true;
    }
    emailMobileHandlerElement.focus();
  }
</script>

<div class="main-container">
  <div class="w-11/12 sm:10/12 md:w-9/12 lg:w-7/12 xl:w-6/12">
    <form
      class="forms-container form w-full p-8 leading-10 rounded-xl filter drop-shadow-md"
    >
      <img
        class="w-16 my-6"
        src="https://morrhq.s3.ap-south-1.amazonaws.com/morr-320x.png"
        alt="Welcome to Morr"
        title="Welcome to Morr"
      />

      {#if isLoginScreen}
        <h1 class="text-center font-bold text-xl">Login to continue</h1>
        <input
          type="hidden"
          id="g-recaptcha-response"
          name="g-recaptcha-response"
        />
        <div class="w-10/12 hero bg-gredient-dark h-20 flex flex-col px-0 mt-4">
          <div class="w-full relative z-0">
            <input
              id={`email-mobile-input`}
              type="text"
              class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
              placeholder=" "
              bind:value={username}
              on:input={emailMobileHandlerHandler}
              bind:this={emailMobileHandlerElement}
            />

            <label
              for="email-mobile-input"
              class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm"
            >
              Enter Email / Mobile Number
            </label>
            {#if username.length > 0}
              {#if !isValidMobileNumber}
                <div
                  class="absolute right-0 duration-300 top-6 -z-1 origin-0 text-gray-500 text-sm text-red-500"
                >
                  <span><i class="fas fa-times-circle" /></span>
                </div>
              {:else if isValidMobileNumber && username.indexOf('@') !== -1}
                <div
                  class="absolute right-0 duration-300 top-6 -z-1 origin-0 text-gray-500 text-sm text-green-500"
                >
                  <span><i class="fas fa-check-circle" /></span>
                </div>
              {/if}
            {/if}
          </div>
        </div>
        {#if username}
          <div
            class="w-10/12 hero bg-gredient-dark h-20 flex flex-col px-0 mt-2"
          >
            <div class="w-full relative z-0">
              <input
                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                id="password"
                type="password"
                placeholder=" "
                bind:value={password}
                on:change={() => {
                  enableFormValidationError('password');
                }}
                use:bindClass={{
                  form: loginForm,
                  invalid:
                    'border-red-500 focus:outline-none focus:border-red-500'
                }}
              />
              <label
                for="password"
                class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm"
              >
                <span>Password</span>
              </label>

              {#if showFormValidationError?.password}
                <div class="messages w-10/12">
                  {#if $loginForm && $loginForm.fields.password?.errors?.includes('required')}
                    <p
                      class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
                    >
                      * The password is required
                    </p>
                  {/if}
                </div>
              {/if}
            </div>
          </div>
        {/if}

        <div class="text-center w-full my-3">
          <button
            class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none text-white"
            type="button"
            on:click={() => performLogin('password')}
            disabled={!$loginForm && $loginForm?.valid}
            >Login {!isEmail ? 'With Password' : ''}</button
          >
        </div>
        {#if username && !isEmail}
          <div
            class="flex w-full text-center mx-auto justify-center align-middle m-auto"
          >
            <div
              class="shadow-inner bg-gray-100 rounded-full w-10 h-10 text-sm font-bold text-black flex items-center justify-center"
            >
              <span> OR </span>
            </div>
          </div>

          <div class="text-center w-full my-3">
            <button
              class="btn w-10/12 bg-gray-200 rounded-3xl uppercase py-0 h-10 font-bold outline-none text-gray-400"
              type="button"
              on:click={() => {
                performLogin('otp');
              }}
            >
              Request OTP
            </button>
          </div>
        {/if}
      {:else if isOTPScreen}
        <h6 class="text-center text-sm">
          Please enter the OTP sent to {emailMobileHandlerInstance.getNumber()}
          <span class="text-blue-300" on:click={() => resetForm()}>
            Change number</span
          >
        </h6>
        <div class="w-10/12 hero bg-gredient-dark h-20 flex flex-col px-0 mt-4">
          <div class="w-full relative z-0">
            <input
              class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
              id="otp"
              type="text"
              placeholder=" "
              bind:value={otp}
              on:change={() => {
                enableFormValidationError('otp');
              }}
              use:bindClass={{
                form: otpForm,
                invalid:
                  'border-red-500 focus:outline-none focus:border-red-500'
              }}
            />
            <label
              for="otp"
              class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm"
            >
              Enter OTP
            </label>

            {#if showFormValidationError?.otp}
              <div class="messages w-10/12">
                {#if $otpForm && $otpForm.fields.otp?.errors?.includes('required')}
                  <p
                    class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
                  >
                    * The OTP is required
                  </p>
                {/if}
              </div>
            {/if}
          </div>
        </div>
        <div class="text-center w-full my-3">
          <button
            class="w-5/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
            type="button"
            on:click={() => performLogin('otp', 'verify')}
            disabled={!otpForm && otpForm?.valid}>Login With Otp</button
          >
          <button
            class={`w-5/12 morr-background-light border-2 rounded-3xl border-opacity-10 border-yellow-600 py-0 h-10 font-bold outline-none ${
              otpResendCountDown !== 0 ? 'opacity-40' : 'opacity-100'
            }`}
            type="button"
            on:click={() => performLogin('otp', 'request_otp')}
            disabled={otpResendCountDown > 0}
          >
            {#if otpResendCountDown !== 0}
              {`Resend in ${otpResendCountDown}`}
            {:else}
              {`Resend OTP`}
            {/if}
          </button>
        </div>
      {/if}

      <div
        class="flex relative mt-4 w-full z-2 justify-between px-16 text-lg font-normal text-black"
      >
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/forgot-password">
            <small>forgot password?</small>
          </Navigate>
        </div>

        <div class="space-x-2 inline-block">
          <Navigate to="/auth/register">
            <small>Create a new account</small>
          </Navigate>
        </div>
      </div>
    </form>
  </div>
</div>

<style global windi:global windi:preflights:global windi:safelist:global>
  .messages {
    display: flex;
    justify-content: flex-start;
  }

  .main-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .check {
    display: flex;
    justify-content: flex-start;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .forms-container {
    background-color: #f5f1ea;
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;
  }

  .form input {
    outline: none;
    border: none;
    border-bottom: 1px solid #b3b1b140;
    background: transparent;
    background-clip: content-box;
  }

  .form-group {
    display: block;
  }

  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #e8ad90e0;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .iti--separate-dial-code .iti__selected-flag {
    background-color: transparent !important;
  }
</style>
