import {navigateTo} from 'svelte-router-spa';

import {isLoggedIn, authToken} from './store/auth';
import {isSuperAdmin} from './store/admin';

import {checkUserPermissions} from './functions/auth';

// Layouts
import MerchantLayout from './layouts/MerchantLayout.svelte';

import Auth from './layouts/Auth.svelte';

//admin
import EmailTemplate from './views/admin/EmailTemplate.svelte';
import HomePageRows from './views/admin/HomePageRows.svelte';

import PromoBanners from './views/admin/PromoBanners.svelte'
import HomePageQuickLink from './views/admin/HomePageQuickLinks.svelte';


// auth
import Login from './views/auth/Login.svelte';
import Logout from './views/auth/Logout.svelte';
import Register from './views/auth/Register.svelte';
import ForgotPassword from './views/auth/ForgotPassword.svelte';
import OTPLogin from './views/auth/OTPLogin.svelte';
import PasswordReset from './views/auth/PasswordReset.svelte';

// user
import UserProfile from './views/user/UserProfile.svelte';
import ChangePassword from './views/user/ChangePassword.svelte';

// Merchant
import CreateMerchant from 'views/merchant/CreateMerchant.svelte';
import UpdateMerchant from 'views/merchant/UpdateMerchant.svelte';
import EditMerchant from 'views/merchant/EditMerchant.svelte';
import MerchantProfile from 'views/merchant/MerchantProfile.svelte';
import MerchantDashboard from 'views/merchant/MerchantDashboard.svelte';
import MerchantList from 'views/merchant/MerchantList.svelte';
import ProductList from 'views/merchant/ProductList.svelte';
import ManagerProfile from 'views/merchant/ManagerProfile.svelte';
import AddManager from 'views/merchant/AddManager.svelte';
import CreateProduct from 'views/merchant/CreateProduct.svelte';
import UpdateProduct from 'views/merchant/UpdateProduct.svelte';
import MerchantStore from 'views/merchant/MerchantStore.svelte';

//Product Template
import CreateProductTemplate from 'views/merchant/CreateProductTemplate.svelte';
import ProductTemplateList from 'views/merchant/ProductTemplateList.svelte';
import UpdateProductTemplate from 'views/merchant/UpdateProductTemplate.svelte';
import ProductTemplateProfile from 'views/merchant/ProductTemplateProfile.svelte';
import BrandList from 'views/merchant/BrandList.svelte';
import CreateBrand from 'views/merchant/CreateBrand.svelte';
import AccessMerchantToBrand from 'views/merchant/AccessMerchantToBrand.svelte';

//Orders

import OrderList from 'views/order/OrderList.svelte';

// payments
import TransactionHistory from 'views/payments/TransactionHistory.svelte';
import PayDirect from 'views/payments/PayDirect.svelte';

//store
import Categories from './views/store/Categories.svelte';

//email-template
import CardEmailTemplateCreate from './components/Cards/EmailTemplate/CardEmailTemplateCreate.svelte';

let isUserLoggerInCheck = false;

function userIsAdmin() {
  authToken.subscribe(value => {
    if (value){
      checkUserPermissions(value);
    }
  });
}

function isUserLoggedIn() {
  isLoggedIn.subscribe(value => {
    isUserLoggerInCheck = value;
    if (value){
      userIsAdmin();
    }
  });
  return isUserLoggerInCheck;
}

function isUserNotLoggedIn(){
  return !isUserLoggedIn();
}

function isUserSuperAdmin() {
  let superAdminStatus = false;
  isSuperAdmin.subscribe(value => {
    superAdminStatus = value;
  });
  return superAdminStatus;
}

const routes = [
  {
    name: '/',
    redirectTo: 'merchants/dashboard',
    onlyIf: {guard: isUserLoggedIn, redirect: 'auth/login'}
  },
  {
    name: 'auth',
    layout: Auth,
    onlyIf: {guard: isUserNotLoggedIn, redirect: 'merchants/dashboard'},
    nestedRoutes: [
      {
        name: 'login',
        component: Login
      },
      {
        name: 'register',
        component: Register
      },
      {
        name: 'password/reset',
        component: PasswordReset
      },
      {
        name: 'forgot-password',
        component: ForgotPassword
      },
      {
        name: 'otp-login',
        component: OTPLogin
      }
    ]
  },
  {
    name: 'dashboard',
    component: '',
    layout: MerchantLayout,
    onlyIf: {guard: isUserLoggedIn, redirect: 'auth/login'},
    nestedRoutes: [
      {
        name: 'home',
        component: MerchantDashboard
      }
    ]
  },
  {
    name: 'merchants',
    component: '',
    layout: MerchantLayout,
    onlyIf: {guard: isUserLoggedIn, redirect: 'auth/login'},
    nestedRoutes: [
      {
        name: 'dashboard',
        component: MerchantDashboard
      },
      {
        name: 'list',
        component: MerchantList
      },
      {
        name: 'profile/:merchant_id',
        component: MerchantProfile
      },
      {
        name: 'create',
        component: CreateMerchant
      },
      {
        name: '/brands',
        nestedRoutes: [
          {
            name: 'list',
            component: BrandList
          },
          {
            name: 'create',
            component: CreateBrand
          },
          {
            name: 'product/template',
            nestedRoutes: [
              {
                name: 'create/:brand_id',
                component: CreateProductTemplate
              },
              {
                name: 'list/:brand_id',
                component: ProductTemplateList
              },
              {
                name: 'update/:brand_id/:product_template_id',
                component: UpdateProductTemplate
              },
              {
                name: 'profile/:brand_id/:product_template_id',
                component: ProductTemplateProfile
              }
            ]
          }
        ]
      },
      {
        name: 'access/merchant',
        component: AccessMerchantToBrand
      },
      {
        name: 'update/:merchant_id',
        component: UpdateMerchant
      },
      {
        name: 'edit/:merchant_id',
        component: UpdateMerchant
      },
      {
        name: 'store',
        nestedRoutes: [
          {
            name: 'categories',
            nestedRoutes: [
              {
                name: ':store_id',
                component: Categories
              }
            ]
          },
          {
            name: 'product',
            layout: '',
            nestedRoutes: [
              {
                name: 'create/:store_id',
                component: CreateProduct
              },
              {
                name: 'list',
                nestedRoutes: [
                  {name: 'brand/:brand_id/:store_id', component: ProductList},
                  {name: ':store_id', component: ProductList}
                ]
              },
              {
                name: 'update/:store_id/:product_id',
                component: UpdateProduct
              }
            ]
          },
          {
            name: ':merchant_id/orders',
            component: OrderList
          }
        ]
      },
      {
        name: 'managers',
        nestedRoutes: [
          {
            name: 'add',
            component: AddManager
          },
          {
            name: ':merchant_id/profile',
            component: ManagerProfile
          }
        ]
      },
      {
        name: ':merchant_id/store',
        component: MerchantStore
      }
    ]
  },
  {
    name: 'payments',
    layout: MerchantLayout,
    onlyIf: {guard: isUserLoggedIn, redirect: 'auth/login'},
    nestedRoutes: [
      {
        name: 'transactions/history',
        component: TransactionHistory
      },
      {
        name: 'cash-pay-direct',
        component: PayDirect
      }
    ]
  },
  {
    name: 'accounts',
    layout: MerchantLayout,
    onlyIf: {guard: isUserLoggedIn, redirect: 'auth/login'},
    nestedRoutes: [
      {
        name: 'profile',
        component: UserProfile
      },
      {
        name: 'change-password',
        component: ChangePassword
      },
      {
        name: 'logout',
        component: Logout
      }
    ]
  },
  {
    name: 'admin/email-template',
    onlyIf: {guard: isUserSuperAdmin, redirect: 'merchants/dashboard'},
    nestedRoutes: [
      {
        name: 'list',
        layout: MerchantLayout,
        component: EmailTemplate
      },
      {
        name: 'create',
        layout: MerchantLayout,
        component: CardEmailTemplateCreate
      }
    ]
  },
  {
    name: 'admin/homepage',
    onlyIf: {guard: isUserSuperAdmin, redirect: 'merchants/dashboard'},
    nestedRoutes: [
      {
        name: 'rows',
        layout: MerchantLayout,
        component: HomePageRows
      },
      {
        name: 'promobanners',
        layout: MerchantLayout,
        component: PromoBanners
      },
      {
         name: 'quicklinks',
         layout: MerchantLayout,
         component: HomePageQuickLink
      }
    ]
  }
];

export {routes};
